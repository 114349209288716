/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

input[type="text"],
input[type="password"],
input[type="email"]:not(.newsletter .ginput_container input[type="email"]),
input[type="telephone"],
input[type="tel"],
textarea,
select {
  @extend .form-control;
}

.gform_button {
  @extend .btn;
  @extend .btn-lg;

  background-color: map-get($theme-colours, "primary");
  border: 1px solid map-get($theme-colours, "primary");
  color: #fff;
}

.gform_next_button,
.gform_previous_button {
  @extend .btn;
  @extend .btn-lg;

  border-radius: 0;
  background-color: map-get($theme-colours, "primary");
  border: 1px solid map-get($theme-colours, "primary");
  color: #fff;
}
