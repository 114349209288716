.menu-overlay {
  background-color: map-get($theme-colours, "primary");
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  color: #fff;
  overflow-y: scroll;
  display: none;
}

.menu-overlay h2 {
  font-size: 3.4rem;
}

.menu-overlay h3 {
  font-size: 3.125rem;
  color: map-get($theme-colours, "primary");
  font-family: gotham_blackregular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.menu-overlay h4 {
  color: #fff;
}

.menu-active {
  display: block !important;
}

.addresses a {
  color: #fff;
}

.addresses a:hover {
  color: map-get($theme-colours, "secondary");
}

.ubermenu .ubermenu-target {
  padding: 15px 0 !important;
}

.gradient-top {
  background: rgb(15, 40, 89);
  background: linear-gradient(180deg, rgba(15, 40, 89, 1) 0%, rgba(15, 40, 89, 0) 100%);
  width: 100%;
  height: 100px;
  position: fixed;
  z-index: 1000;
}

.menu-case .card {
  color: map-get($theme-colours, "primary");
  font-size: 0.9rem;
}

.menu-case .card h5 {
  font-size: 1rem;
  font-family: "Cocon", sans-serif;
  font-weight: 700;
}
