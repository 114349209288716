@font-face {
  font-family: "Cocon";
  src:
    url("../../fonts/cocon/Cocon-Bold.woff2") format("woff2"),
    url("../../fonts/cocon/Cocon-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

html,
body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  top: 0 !important;
  overflow-x: hidden;
  color: #000 !important;
}

a {
  text-decoration: none;
}

.ubermenu-target:hover {
  color: map-get($theme-colours, "accent") !important;
}

h1,
h2,
h3 {
  font-family: "Cocon", sans-serif;
  font-weight: 700;
  color: map-get($theme-colours, "primary");
}

h4 {
  font-family: "Cocon", sans-serif;
  font-weight: 700;
  color: map-get($theme-colours, "primary");
  text-transform: capitalize;
}

b,
strong,
.bold {
  font-family: "Cocon", sans-serif;
  font-weight: 700;
}

hr {
  background-color: map-get($theme-colours, "greyish") !important;
  opacity: 1 !important;
}

::-moz-selection {
  color: map-get($theme-colours, "primary") !important;
  background: map-get($theme-colours, "accent") !important;
}

::selection {
  color: map-get($theme-colours, "primary") !important;
  background: map-get($theme-colours, "accent") !important;
}

.item-block {
  padding: 2rem;
  border: 1px solid map-get($theme-colours, "greyish") !important;
  position: relative;
}

.item-block::after {
  content: "";
  width: 25%;
  height: 5px;
  position: absolute;
  background-color: map-get($theme-colours, "accent") !important;
  bottom: 0;
  left: 0;
}

.item-block h3 {
  font-size: 25px;
}

.item-block .wp-block-button {
  width: 100% !important;
}

.wp-block-button__link:hover {
  color: #fff;
}

.item-block .wp-block-button .wp-block-button__link {
  border-radius: 0 !important;
  width: 100% !important;
  font-family: "Cocon", sans-serif;
  font-weight: 700;
  background-color: map-get($theme-colours, "primary") !important;
}

.testimonial {
  background-color: map-get($theme-colours, "accent") !important;
  padding: 2rem;
  color: map-get($theme-colours, "primary") !important;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

#page_id {
  border: none;
  border-bottom: 1px solid map-get($theme-colours, "primary") !important;
  min-height: 50px;
  text-transform: uppercase;
  font-family: "Cocon", sans-serif;
  font-weight: 700;
}

#page_id:focus-visible {
  outline: none;
}

.revslider br {
  display: none;
}

.revslider ul {
  list-style: none;
  padding: 0;
}

.revslider li {
  padding-left: 1.3em;
}

.revslider ul li::before, {
  content: "\f00c";
  font-family: "FontAwesome", sans-serif;
  display: inline-block;
  margin-left: -1.3em;
  width: 1.3em;
}

.main-image-service-large {
  position: relative;
  top: 0;
  right: 0;
}

.entry-content {
  z-index: 10;
  position: relative;
}

.breakout {
  margin: 1em -100%;
  margin: 1em calc(50% - 50vw);
}

rs-sbg-px {
  display: none !important;
}

.ubermenu .ubermenu-item-level-0 > .ubermenu-submenu-type-stack {
  padding: 0 20px;
}

.ubermenu .ubermenu-submenu-type-stack > .ubermenu-item-normal > .ubermenu-target {
  color: #fff;
}

.ubermenu .ubermenu-item-level-0 > .ubermenu-submenu-type-stack > .ubermenu-item,
.right-side .ubermenu .ubermenu-submenu-type-stack > .ubermenu-item.ubermenu-column-auto {
  background-color: map-get($theme-colours, "menu");
  border-left: 5px solid map-get($theme-colours, "accent");
}

.ubermenu .ubermenu-item-level-0 > .ubermenu-submenu-type-stack > .ubermenu-item:hover,
.right-side .ubermenu .ubermenu-submenu-type-stack > .ubermenu-item.ubermenu-column-auto:hover {
  background-color: map-get($theme-colours, "accent");
  border-left: 5px solid #fff;
}

.right-side .ubermenu-target-description::after {
  content: "Read more \2192";
  clear: both;
  display: block;
  margin-top: 20px;
}

.right-side .ubermenu .ubermenu-item-type-column > .ubermenu-submenu-type-stack > .ubermenu-item-normal:first-child {
  margin-top: 30px;
}

.right-side .ubermenu-target-title {
  font-size: 1.7rem;
  font-weight: 700;
  text-transform: uppercase;
  color: map-get($theme-colours, "accent");
  padding-bottom: 10px;
}

.ubermenu .ubermenu-item-level-0 > .ubermenu-submenu-type-stack > .ubermenu-item:hover .ubermenu-target-title,
.ubermenu .ubermenu-item-level-0 > .ubermenu-submenu-type-stack > .ubermenu-item:hover .ubermenu-target-description,
.ubermenu .ubermenu-submenu-type-stack > .ubermenu-item.ubermenu-column-auto:hover .ubermenu-target-title,
.ubermenu .ubermenu-submenu-type-stack > .ubermenu-item.ubermenu-column-auto:hover .ubermenu-target-description {
  color: map-get($theme-colours, "primary");
}

.team-content {
  font-size: 0.9rem;
}

.team-content ul {
  list-style: none;
}

.team-content ul li::before, {
  content: "\f00c";
  font-family: "FontAwesome", sans-serif;
  display: inline-block;
  margin-left: -1.3em;
  width: 1.3em;
  color: map-get($theme-colours, "accent");
}

.staff h2 {
  color: map-get($theme-colours, "secondary");
}

.underline-bold {
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: black;
}

.underline-blue {
  text-decoration: underline !important;
  text-underline-offset: 3px !important;
  text-decoration-thickness: 2px !important;
  text-decoration-color: map-get($theme-colours, "accent") !important;
}

.left-side .ubermenu .ubermenu-target-text::before {
  content: "";
  border-left: 5px solid map-get($theme-colours, "accent") !important;
  margin-right: 20px;
}

.wp-block-column ul {
  list-style: none;
  padding: 0;
}

.wp-block-column ul li {
  margin: 10px 0;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ececec;
  padding: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.wp-block-column ul li::before {
  content: "\f00c";
  font-family: "FontAwesome", sans-serif;
  margin-right: 5px;
  color: map-get($theme-colours, "accent");
}

.underline-text {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: map-get($theme-colours, "accent");
}

rs-static-layers {
  overflow: visible !important;
}

.dark-blue-text {
  color: map-get($theme-colours, "primary");
}

.quote-mark {
  width: 20%;
}

.st {
  left: 140px !important;
}

.qtech-arrow.tparrows {
  left: -140px !important;
}
