@media only screen and (min-width: 320px) and (max-width: 500px) {
  h1 {
    font-size: calc(1.375rem + 3.5vw);
  }

  .newsletter .gform_wrapper.gravity-theme .gfield.gfield--width-half {
    grid-column: span 8 !important;
  }

  .newsletter #field_submit {
    grid-column: span 4 !important;
  }

  .float-image {
    position: absolute !important;
    bottom: -20px !important;
    left: -10px !important;
  }

  #page_id {
    width: 100% !important;
  }

  .testimonial {
    position: relative;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  .services-block h3 {
    font-size: 0.9rem;
  }

  .st {
    left: 0 !important;
  }

  .tparrows {
    left: -140px !important;
  }

  .right-side .ubermenu .ubermenu-nav .ubermenu-column-1-2 {
    width: 100% !important;
  }

  .right-side .ubermenu .ubermenu-item-level-0 > .ubermenu-submenu-type-stack {
    padding: 0 !important;
  }
}

@media only screen and (min-width: 501px) and (max-width: 960px) {
  .newsletter .gform_wrapper.gravity-theme .gfield.gfield--width-half {
    grid-column: span 8 !important;
  }

  .newsletter #field_submit {
    grid-column: span 4 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .vc_col-sm-3 {
    width: 33%;
  }

  .vc_col-sm-4 {
    width: 50%;
  }

  .testimonial {
    position: relative;
    bottom: 0;
    width: 100%;
    left: 0;
  }
}

@media only screen and (min-width: 961px) and (max-width: 1100px) {
  .right-side .ubermenu .ubermenu-nav .ubermenu-column-1-2 {
    width: 100% !important;
  }
}
