.newsletter #field_submit {
  grid-column: span 3 !important;
}

footer.content-info {
  background-color: map-get($theme-colours, "accent");
}

footer.content-info .gform_wrapper.gravity-theme .gfield.gfield--width-half {
  grid-column: span 9;
  -ms-grid-column-span: 9;
}

.newsletter .gform_footer {
  margin: 0 !important;
  padding: 0 !important;
}

.newsletter #input_1_1 {
  border: 1px solid #000;
  padding: 14px;
}

.newsletter .gfield_description {
  display: none;
}

.newsletter #gform_submit_button_1 {
  padding: 13.3px;
  border-radius: 0;
  border: 1px solid #000;
  background-color: map-get($theme-colours, "primary");
  color: #fff;
  margin-left: -20px;
  font-weight: 700;
}

.newsletter #desc {
  font-size: 1.25rem;
}

.menu-footer-menu-container li {
  @extend .me-4;
}

.blue-footer {
  background-color: map-get($theme-colours, "primary");
  color: #fff;
}

.blue-footer a {
  color: #fff !important;
}

.credits h4 {
  color: #fff !important;
}
