// Note: Bootstrap 5 ships with its own default theme colors
:root {
  --bs-blue: #0f2859;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0f2859;
  --bs-secondary: #7787a6;
  --bs-success: #198754;
  --bs-info: #384d59;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #082040;
}

$theme-colours: (
  primary: #0f2859,
  secondary: #7787a6,
  accent: #80f2e7,
  block: #082040,
  info: #384d59,
  menu: #3f537a,
  greyish: #e8e8e8,
);

$container-max-widths: (
  xl: 1440px
);

$link-color: #0f2859;
