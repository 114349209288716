.services-block {
  border: 1px solid map-get($theme-colours, "greyish");
  background: white;
}

.services-block a {
  display: block;
  padding: 30px;
  padding-top: 40px;
  text-align: center !important;
  min-height: 250px;
  border-right: 1px solid map-get($theme-colours, "greyish");
  border-bottom: 1px solid map-get($theme-colours, "greyish");
}

.services-block a:hover {
  background-color: lighten(map-get($theme-colours, "greyish"), 7) !important;
}

.services-block h3 {
  text-align: center !important;
  color: map-get($theme-colours, "primary");
  font-size: 1.2rem;
}

.swiper-slide {
  text-align: center !important;
}

.img-block {
  text-align: center !important;
}

.swiper-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-auto-flow: column;

  & > .swiper-slide {
    height: fit-content;
  }
}
