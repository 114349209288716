.banner .nav li {
  @extend .nav-item;
}

header.banner a {
  color: #fff;
}

.banner .nav a {
  @extend .nav-link;
}

header.banner {
  color: #fff;
  z-index: 1000;
  top: 0;
}

header.banner .fa-inverse {
  color: map-get($theme-colours, "block") !important;
}

.bar-menu span {
  font-size: 1.1rem;
}

.slider-block {
  z-index: 0;
}

.page-header-bkg {
  background-color: map-get($theme-colours, "primary") !important;
}
