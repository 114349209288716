.format-standard {
  @extend .mt-5;
  @extend .mb-5;
}

header h1 {
  text-decoration: underline;
  text-underline-offset: 7px;
  text-decoration-color: map-get($theme-colours, "accent");
}
