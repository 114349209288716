.btn {
  border-radius: 0;
  padding: 0.475rem 1rem;
}

.btn-primary {
  border-color: map-get($theme-colours, "accent") !important;
  color: map-get($theme-colours, "primary") !important;
  background-color: map-get($theme-colours, "accent") !important;
}

.btn-white {
  border-color: #fff;
  color: map-get($theme-colours, "block") !important;
  background-color: #fff;
}

.btn-white:hover {
  border-color: map-get($theme-colours, "accent");
  color: map-get($theme-colours, "block") !important;
  background-color: map-get($theme-colours, "accent");
}

.vc_grid-filter.vc_grid-filter-color-blue > .vc_grid-filter-item.vc_active,
.vc_grid-filter.vc_grid-filter-color-blue > .vc_grid-filter-item:hover {
  background-color: map-get($theme-colours, "primary") !important;
}
